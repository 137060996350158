import React, { Component } from 'react';
import DeviceDetector from "device-detector-js";
// import { BrowserRouter, Link } from 'react-router-dom';
// import axios from 'axios';

// components
import Hero from '../components/Hero';

class Page extends Component {
  state = {
    isLoaded: false, appleLink: 'https://apps.apple.com/us/app/usahs-mobile/id1612825412', googleLink: 'https://play.google.com/store/apps/details?id=com.unifyed.usaprod'
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.querySelector('#main').classList.add('hero');

    const deviceDetector = new DeviceDetector();
    const userAgent = window.navigator.userAgent;
    const device = deviceDetector.parse(userAgent);

    if(device.device.type === 'smartphone') {
      console.log('Smartphone detected.....');
      if (device.os.name === 'iOS') {
        console.log('Apple OS detected.....');
        // window.location.href = this.state.appleLink;
      } else if (device.os.name === 'Android') {
        console.log('Android OS detected.....');
        // window.location.href = this.state.googleLink;
      }
    }
  }

  render() {
    const { appleLink, googleLink } = this.state;
    // console.dir(isLoaded);

    return (
      <>
      <Hero page="homepage" />

      <div className="homepage">
        <div className="container-fluid">
          <div className="row">

            <div className="container">
              <div className="row">
                <div className="col-3">
                  <img src={process.env.PUBLIC_URL + '/global/images/icons/myusahs-appIcon.png'} alt="What are the qualifications?" className="fullWidth" />
                </div>

                <div className="col-9">
                  <h2>USAHS Mobile</h2>
                  <p>USAHS Mobile, the official mobile app for the University of St. Augustine for Health Sciences, is your on-the-go connection to the University! Easily access USAHS information and resources from your mobile device- anytime, anywhere! Some of the exciting features include:</p>
                  <ul>
                    <li>Quick Links to commonly used sites and information</li>
                    <li>Information about our graduate degree programs</li>
                    <li>Campus information including our 360 degree virtual tours, in-person tour information, campus maps and more</li>
                    <li>View and register for upcoming events</li>
                    <li>Meet our Faculty</li>
                    <li>News and social media feeds</li>
                    <li>Access to Blackboard for courses and related information</li>
                    <li>Access to our online Library</li>
                    <li>Safety and Security information for around the campus and more!</li>
                  </ul>

                  <div className="appStoreLinks">
                    <a href={ appleLink } target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + '/global/images/icons/appStore.png'} alt="Download the iOS App" /></a>
                    <a href={ googleLink } target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + '/global/images/icons/googlePlay.png'} alt="Download the Android App" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row highlightSection">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <img src={process.env.PUBLIC_URL + '/global/images/photos/patient.jpg'} alt="What are the qualifications?" className="fullWidth" />
                  <h2>What are the qualifications?</h2>
                  <p>Basic training and preparation will be provided. SPs should demonstrate excellent communication skills and be able to follow verbal and written instructions in order to simulate all aspects of the scenarios consistently and accurately. SPs will be required to wear a hospital gown and personal protective equipment (PPE) and/or be comfortable having physical examination procedures performed as indicated. Some scenarios may be filmed with SP permission and release and used for instructional purposes only.</p>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <img src={process.env.PUBLIC_URL + '/global/images/photos/holdinghands.jpg'} alt="What is the pay?" className="fullWidth" />
                  <h2>What is the pay?</h2>
                  <p>Standardized patients are retained as Independent Contractors. Our standardized patients are paid a rate of $20.00 per hour for both telehealth &amp; site simulations.</p>
                  <p className="note">Note: USAHS employees are not eligible for compensation as standardized patients, but spouses, family members and friends are eligible.</p>
                </div>
              </div>
            </div>
          </div> */}

        </div>
      </div>
      </>
    );
  }
}

export default Page;