import React, { Component } from 'react';
// import { BrowserRouter, Link } from 'react-router-dom';
// import axios from 'axios';

// components
// import Hero from '../components/Hero';

class Page extends Component {
  state = {
    isLoaded: false
  }

  componentDidMount() {
    // window.scrollTo(0, 0);
    // document.querySelector('#main').classList.add('hero');

    // redirect to form
    window.location.href = 'https://forms.office.com/pages/responsepage.aspx?id=If31YBj82Eu4VdWgKUvY3oJSpRielbpFinIXZYT2FvlUN1NVN05ITFgzMDJBWEE2T0ZRRkZPT0pFOC4u';
  }

  render() {
    // const { appleLink, googleLink } = this.state;
    // console.dir(isLoaded);

    return (
      <>
      <div className="homepage">
        <div className="container-fluid">
          <div className="row">

          </div>
        </div>
      </div>
      </>
    );
  }
}

export default Page;