import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import axios from 'axios';
// import { decode } from 'html-entities';

export class MainNav extends Component  {
  state = {
    mainnav: []
  }

  componentDidMount() {
    const navCont = document.querySelector('#main-nav');
    const links = document.querySelectorAll('#main-nav a');
    const navBttn = document.querySelector('.hamburger');

    links.forEach((e) => {
      e.addEventListener('click', function() {
        navCont.classList.toggle('open');
        navBttn.classList.toggle('open');
      });
    });
  }

  render() {
    return (
      <>
        <nav id="main-nav" className="slidingNav">
          <ul>
            <li><Link to="/"><i className="fa fa-mobile"></i> App Information</Link></li>

            <li><Link to="/feedback"><i className="fa fa-comment"></i> Mobile App Feedback</Link></li>
          </ul>

          <div className="nav-copyright">
            <img src={process.env.PUBLIC_URL + '/global/images/logos/usa-logo-whitesm.png'} alt="University of St. Augustine for Health Sciences" />
          </div>
        </nav>
      </>
    );
  }
}

export default MainNav;