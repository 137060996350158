import React, { Component } from "react"
// import { useHistory } from 'react-router-dom';
// import axios from 'axios';

export class Hero extends Component {
	state = {}

	componentDidMount() {}

	render() {
		// const { mobileMenu } = this.state;
		// console.dir(this.state);

		return (
			<>
				<div
					id="hero"
					className={this.props.page}
				>
					<div className="container">
						<div className="row">
							<div className="col-7">
								<h1>
									<span>Mobile Apps</span>
									Keeping you Connected
								</h1>
								<br /><br />
								<p>
									Already have the app? &nbsp;
									<a
										href="https://forms.office.com/pages/responsepage.aspx?id=If31YBj82Eu4VdWgKUvY3oJSpRielbpFinIXZYT2FvlUN1NVN05ITFgzMDJBWEE2T0ZRRkZPT0pFOC4u"
										title="Mobile App Feedback"
										target="_blank"
										rel="noreferrer"
									>
										Provide Feedback!
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

export default Hero
