import React, { Component } from "react"
// import { BrowserRouter, Link } from 'react-router-dom';
import axios from "axios"
import parse from "html-react-parser"
import ReactDomServer from "react-dom/server"

export class Footer extends Component {
	state = {
		nav1: [],
		nav2: [],
		social: [],
		remoteFooter: null
	}

	getYear = () => {
		return new Date().getFullYear()
	}
	componentDidMount() {
		axios({
			url: "https://www.usa.edu",
			auth: {
				username: "usahs",
				password: "usahsMarketing"
			}
		})
			.then((resp) => {
				const remote_html = parse(resp.data)
				this.setState((prev) => {
					this.remoteFooter = ReactDomServer.renderToString(
						remote_html.props.children[1].props.children[16]
					).replaceAll("fa fa", "fab fa")
					this.forceUpdate()
				})
			})
			.catch((error) => {
				console.log(error)
			})
	}

	render() {
		return (
			<>
				<div dangerouslySetInnerHTML={{ __html: this.remoteFooter }} />
			</>
		)
	}
}

export default Footer
