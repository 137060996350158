import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom'

// components

// pages
import Static from '../pages/Static';
import Homepage from '../pages/Homepage';
import Feedback from '../pages/Feedback';
import MainNav from './MainNav';

function Main() {
  return (

    <section id="main">
      <BrowserRouter>
        <MainNav />
        <Routes>
            <Route path="/" element={ <Homepage />} />
            <Route path="/feedback" element={ <Feedback />} />
            <Route path="/static" element={ <Static />} />
         </Routes>
      </BrowserRouter>
    </section>

  );
}

export default Main;