import React, { Component } from "react"
import axios from "axios"
import parse from "html-react-parser"
import ReactDomServer from "react-dom/server"
// import { BrowserRouter, Link } from 'react-router-dom';
// import axios from 'axios';

// components

export class Header extends Component {
	state = {
		remoteHeader: null
	}

	componentDidMount() {
		axios({
			url: "https://www.usa.edu",
			auth: {
				username: "usahs",
				password: "usahsMarketing"
			}
		})
		.then((resp) => {
			let txt = resp.data.replace(/png'/g, "png")
			let remote_html = parse(txt)


			 let elements = remote_html.props.children[1].props.children.filter(function( obj ) {
				return typeof obj == "object"
			});


			let local_html = ReactDomServer.renderToString(
				elements[2]
			)

			let regex1 = new RegExp(
				'<img.+?data-src="(.+?)" class="unstuck.+?" src="(.+?)"/>',
				"g"
			)
			let results1 = regex1.exec(local_html)
			local_html = local_html.replaceAll(results1[2], results1[1])

			let stuck_incorrect_string =
				'<img alt="University of St. Augustine for Health Sciences" data-src="https://www.usa.edu/wp-content/themes/mast/dist/img/usa-logo-horizontal.png" class="stuck-logo lazyload" src="https://i0.wp.com/www.usa.edu/wp-content/uploads/2022/01/USA-logo.jpg?fit=410%2C410&amp;ssl=1"/>'

			let stuck_correct_string = 
				'<img alt="University of St. Augustine for Health Sciences" data-src="https://www.usa.edu/wp-content/themes/mast/dist/img/usa-logo-horizontal.png" class="stuck-logo lazyloaded" src="https://www.usa.edu/wp-content/themes/mast/dist/img/usa-logo-horizontal.png">'

			local_html = local_html.replaceAll(stuck_incorrect_string, stuck_correct_string)
console.log("here")
			this.setState((prev) => {
				this.remoteHeader = local_html

				const script = document.createElement("script")
				script.src =
					"https://www.usa.edu/wp-content/themes/mast/dist/js/app.js"
				script.async = true
				document.body.appendChild(script)

				this.forceUpdate()
			})
		})
		.catch((error) => {
			console.log(error)
		})
	}

	topOfContent = () => {
		let form = document.querySelector("#main")
		const y = form.offsetTop - 25

		window.scrollTo({
			top: y,
			behavior: "smooth"
		})
	}

	navToggle = () => {
		const navCont = document.querySelector("#navigation")
		navCont.classList.toggle("open")

		const hiddenLg = document.querySelector(".hidden-lg")
		hiddenLg.classList.toggle("open")
	}

	closeToggle = (ev) => {
		if (ev.target.innerText === "CLOSE") {
			this.navToggle()
		}
	}

	render() {
		return (
			<section onClick={this.closeToggle}>
				<div class="hidden-lg">
					<p
						id="menu-item-78077"
						class="menu-orange menu-font-large menu-item menu-item-type-post_type menu-item-object-page menu-item-78077"
					>
						<a href="https://www.usa.edu/rfi/">
							Request Information{" "}
							<i
								class="fas fa-angle-right"
								aria-hidden="true"
							></i>
						</a>
					</p>
					<p
						id="menu-item-78078"
						class="menu-font-large menu-item menu-item-type-post_type menu-item-object-page menu-item-78078"
					>
						<a href="https://www.usa.edu/apply-now/">
							Apply Now{" "}
							<i
								class="fas fa-angle-right"
								aria-hidden="true"
							></i>
						</a>
					</p>
				</div>
				<>
					<div dangerouslySetInnerHTML={{ __html: this.remoteHeader }} />
				</>
				<div
					id="mini-nav"
					onClick={this.navToggle}
				>
					<button
						aria-pressed="false"
						id="ssme-search"
						class="ssme-search"
					>
						<div class="ssme-wrapper">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512"
								class="supersideme__icon ssme-icon search"
								fill="currentcolor"
								height="1em"
								width="1em"
								aria-hidden="true"
								focusable="false"
								role="img"
							>
								<path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
							</svg>
							<span class="label">Search</span>
						</div>
					</button>
					<div class="hamburger">
						<div class="bar"></div>
						<div class="bar"></div>
						<div class="bar"></div>
					</div>
					<p>Menu</p>
				</div>
			</section>
		)
	}
}

export default Header
